<template>
  <div style="height: 100%;">
    <div id="container" style="height: 100%"></div>
  </div>
</template>

<script>
import {Graph, Node, Edge, Shape} from '@antv/x6'
import insertCss from 'insert-css'
import {nextTick, onMounted, onUnmounted} from "@vue/composition-api";
import {TreeNode, TreeEdge} from "@/views/front/common/agent-list/components/treex6Hooks";
import Hierarchy from '@antv/hierarchy'

insertCss(`
  @keyframes ant-line {
    to {
        stroke-dashoffset: -1000
    }
  }
`)

export default {
  name: "DagDemo",
  components: {},

  data() {
    return {
      data1: {
        id: "g1",
        name: "回风温度过高",
        count: 123456,
        label: "538.90",
        currency: "m³",
        rate: 1.0,
        status: "B",
        variableName: "空调系统",
        state: '1',
        "data": {
          "label": "逻辑回归",
          "status": "1"
        },
        variableValue: 0.341,
        variableUp: false,
        isLoading: true,
        children: [
          {
            id: "g12",
            name: "风量不足",
            count: 123456,
            label: "338.00",
            rate: 0.627,
            state: '1',
            "data": {
              "label": "逻辑回归",
              "status": "1"
            },
            status: "R",
            currency: "m³",
            variableName: "新风系统",
            variableValue: 0.179,
            variableUp: true,
            children: [
              {
                id: "g121",
                name: "回风温度高于上限风机频率未达上限值",
                collapsed: false,
                count: 123456,
                label: "138.00",
                rate: 0.123,
                status: "B",
                state: '1',
                "data": {
                  "label": "逻辑回归",
                  "status": "1"
                },
                currency: "m³",
                variableName: "新风系统",
                variableValue: 0.27,
                variableUp: true,
                children: [
                  {
                    id: "g1211",
                    name: "风机控制策略存在问题",
                    count: 123456,
                    label: "138.00",
                    state: '2',
                    "data": {
                      "label": "逻辑回归",
                      "status": "1"
                    },
                    rate: 1.0,
                    status: "B",
                    currency: "m³",
                    variableName: "制冷系统",
                    variableValue: 0.164,
                    variableUp: false,
                    children: [],
                  },
                ],
              },
              {
                id: "g122",
                name: "回风阀未全开",
                collapsed: false,
                count: 123456,
                label: "100.00",
                state: '1',
                "data": {
                  "label": "逻辑回归",
                  "status": "1"
                },
                rate: 0.296,
                status: "G",
                currency: "m³",
                variableName: "制冷系统",
                variableValue: 0.259,
                variableUp: true,
                children: [],
              },
              {
                id: "g123",
                name: "送风阀未全开",
                collapsed: false,
                count: 123456,
                label: "100.00",
                state: '1',

                "data": {
                  "label": "逻辑回归",
                  "status": "1"
                },
                rate: 0.296,
                status: "DI",
                currency: "m³",
                variableName: "新风系统",
                variableValue: 0.131,
                variableUp: false,
                children: [],
              },
              {
                id: "g124",
                name: "风机出力不足",
                collapsed: false,
                count: 123456,
                label: "100.00",
                rate: 0.296,
                state: '1',
                "data": {
                  "label": "逻辑回归",
                  "status": "1"
                },
                status: "DI",
                currency: "m³",
                variableName: "新风系统",
                variableValue: 0.131,
                variableUp: false,
                children: [
                  {
                    id: "g1241",
                    name: "风机磨损或需要维护",
                    collapsed: false,
                    count: 123456,
                    label: "100.00",
                    rate: 0.296,
                    status: "DI",
                    state: '1',
                    "data": {
                      "label": "逻辑回归",
                      "status": "1"
                    },
                    currency: "m³",
                    variableName: "新风系统",
                    variableValue: 0.131,
                    variableUp: false,
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: "g13",
            name: "水侧供冷量不足",
            count: 123456,
            label: "100.90",
            rate: 0.187,
            state: '1',
            "data": {
              "label": "逻辑回归",
              "status": "1"
            },
            status: "B",
            currency: "m³",
            variableName: "新风系统",
            variableValue: 0.221,
            variableUp: true,
          },
        ],
      },
      playIndex: 0,
      timerCloseNumber: [],
      graph: null,
      animationState: false
    }
  },

  mounted() {
    const model = {nodes: [], edges: []}
    const traverse = (data) => {
      if (data) {
        model.nodes?.push({
          id: `${data.id}`,
          attrs: {
            label: {
              textWrap: {
                text: `${data.data.name.length > 10 ? data.data.name.substr(0, 10) + "..." : data.data.name}`
              },
            },
            system: {
              textWrap: {
                text: `${data.data.variableName.length > 10 ? data.data.variableName.substr(0, 10) + "..." : data.data.variableName}`
              },
            },
          },
          x: data.x + 300,
          y: data.y + 450,
          shape: 'tree-node',
          width: 16,
          height: 16,
        })
      }
      if (data.children) {
        data.children.forEach((item) => {
          model.edges?.push({
            source: `${data.id}`,
            target: `${item.id}`,

            shape: "tree-edge",
            attrs: {
              line: {
                stroke: '#82868b',
                strokeWidth: 1,
                targetMarker: null,
              },
            },
          })
          traverse(item)
        })
      }
    }
    Node.registry.register('tree-node', TreeNode, true)
    Edge.registry.register('tree-edge', TreeEdge, true)
    // 初始化画布
    setTimeout(() => {
      this.graph = new Graph({
        container: document.getElementById('container'),
        // async: true,
        // frozen: true,
        // scroller: true,
        // interacting: false,
        panning: {
          enabled: true
        },
        sorting: 'approx',
        connecting: {
          anchor: 'orth',
          connector: 'rounded',
          connectionPoint: 'boundary',
          router: {
            name: 'er',
            args: {
              offset: 24,
              direction: 'H',
            },
          },
        },
      })
      this.graph.on('node:collapse', ({node}) => {
        node.toggleCollapse()
        const collapsed = node.isCollapsed()
        const run = (pre) => {
          const succ = this.graph.getSuccessors(pre, {distance: 1})
          if (succ) {
            succ.forEach((node) => {
              node.toggleVisible(!collapsed)
              if (!node.isCollapsed()) {
                run(node)
              }
            })
          }
        }
        run(node)
      })


      const result = Hierarchy.mindmap(this.data1, {
        direction: 'H',
        getHeight() {
          return 40
        },
        getWidth() {
          return 40
        },
        getHGap() {
          return 100
        },
        getVGap() {
          return 20
        },
        getSide: () => {
          return 'right'
        },
      })

      traverse(result)

      this.graph.fromJSON(model)
      this.graph.unfreeze()
      // graph.unfreeze({
      //   progress({done}) {
      //     if (done) {
      //       const time = new Date().getTime() - start
      //       graph.unfreeze({
      //         batchSize: 50,
      //       })
      //
      //     }
      //   },
      // })

      this.playAnimation(this.data1)
    }, 100)


  },
  // unmounted() {
  //   for (let i of this.timerCloseNumber) {
  //     clearTimeout(i)
  //   }
  // },
  methods: {
    innerManualPlayAnimation() {
      this.timerCloseNumber = []
      this.graph.getNodes().forEach(node => {
        node.attr('process/width', 0)
        node.attr('process/fill', '#1890ff')
        node.attr('stateText/textWrap/text', '')
        node.attr(['state', 'xlink:href'], '')
      })
      this.playIndex = 0
      this.playAnimation(this.data1)
    },
    playAnimation(node, animationState = true) {
      const currentNode = this.graph.getCellById(node.id)
      this.animationState = true
      this.$emit('changeState', this.animationState)
      // 执行动画
      this.timerCloseNumber.push(
          setTimeout(() => {
            let process = 0

            currentNode.attr('stateText/textWrap/text', '诊断中...')
            const incrementFn = () => {
              currentNode.attr('process/width', ++process)

              if (process < 180) {
                this.timerCloseNumber.push(setTimeout(incrementFn, 3000 / 180));
              } else {
                if (!animationState) {
                  this.animationState = animationState
                  this.$emit('changeState', this.animationState)
                }
                // if (node.state && node.state === '2') {
                //   currentNode.attr('process/fill', '#ea5455')
                //   // currentNode.attr('state/textWrap/text', '异常')
                //   currentNode.attr(['state', 'xlink:href'], 'https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/danger.png')
                //   // currentNode.attr('state/fill', '#ea5455')
                // } else if (node.state && node.state === '1') {
                //   currentNode.attr('process/fill', '#28c76f')
                //   // currentNode.attr('state/textWrap/text', '正常')
                //   currentNode.attr(['state', 'xlink:href'], 'https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/success.png')
                // }
                if (node.state && node.state === '2') {
                  currentNode.attr('process/fill', '#ea5455')
                  currentNode.attr('stateText/textWrap/text', '')
                  currentNode.attr('state/textWrap/text', '异常')
                  currentNode.attr('state/fill', '#ea5455')
                  currentNode.attr(['state', 'xlink:href'], 'https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/danger.png')
                } else if (node.state && node.state === '1') {
                  currentNode.attr('process/fill', '#28c76f')
                  currentNode.attr('stateText/textWrap/text', '')
                  currentNode.attr('state/textWrap/text', '正常')
                  currentNode.attr(['state', 'xlink:href'], 'https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/success.png')
                }
              }
            }
            incrementFn()
          }, 3000 * this.playIndex)
      )
      // 三秒后执行
      const nodeTotal = this.graph.getNodes().length
      if (node.children && node.children.length) {
        for (let innerNode of node.children) {
          this.playIndex++
          if (this.playIndex + 1 >= nodeTotal) {
            console.log('结束')
            this.playAnimation(innerNode, false)
          } else {
            this.playAnimation(innerNode)
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
