<template>
    <div>
        <!-- <page-title v-if="!hideTitle"></page-title> -->
        <function-entry></function-entry>

        <b-row>
            <b-col cols="2">
                <b-card>
                    <label class="mt-1">选择日期</label>
                    <flat-pickr
                        v-model="filterValues.time"
                        placeholder="选择日期"
                        key="start_time"
                        :config="{
                            time_24hr: true,
                            // mode: 'range',
                            locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                        }"
                        class="form-control"
                    />
                    <label>类型</label>
                    <v-select
                        v-model="filterValues.Type"
                        :options="filterOptions.Type"
                        variant="outline-primary"
                        placeholder="选择类型"
                    />

                    <label class="mt-1">程度</label>
                    <v-select
                        v-model="filterValues.Degree"
                        :options="filterOptions.Degree"
                        variant="outline-primary"
                        placeholder="选择程度"
                    />

                    <v-jstree
                        :data="myDataArray"
                        @item-click="hanleClickTreeItem"
                        allow-batch
                        class="mt-1"
                    >
                        <template slot-scope="_">
                            <div style="display: inherit">
                                {{ _.model.text }}
                                <button
                                    style="
                                        border: 0px;
                                        background-color: transparent;
                                        cursor: pointer;
                                    "
                                ></button>
                            </div>
                        </template>
                    </v-jstree>
                </b-card>
            </b-col>

            <b-col cols="10">
                <!-- <b-alert show variant="warning" class="mb-2"
                    ><div class="alert-body">
                        <b-icon-exclamation-octagon-fill
                            class="mr-50"
                        ></b-icon-exclamation-octagon-fill
                        >当前故障
                    </div></b-alert
                > -->
                <b-row>
                    <b-col cols="3">
                        <b-card
                            bg-variant="primary"
                            text-variant="light"
                            class="row1"
                        >
                            <b-card-title class="font-weight-bolder text-light">
                                <b-icon-exclamation-circle-fill
                                    variant="light"
                                    class="mr-50"
                                ></b-icon-exclamation-circle-fill>
                                报警
                            </b-card-title>
                            <b-card-text>
                                <p>
                                    <b-icon-clock-fill
                                        class="mr-50"
                                        variant="danger"
                                    ></b-icon-clock-fill>
                                    报警ID
                                    <span class="float-right">{{
                                        detail.id
                                    }}</span>
                                </p>

                                <p>
                                    <b-icon-exclamation-triangle-fill
                                        class="mr-50"
                                        variant="success"
                                    ></b-icon-exclamation-triangle-fill>
                                    报警设备
                                    <b-badge
                                        variant="success"
                                        class="float-right"
                                        >{{ detail.equsid }}#{{ detail.eqname }}
                                    </b-badge>
                                </p>

                                <p>
                                    <b-icon-exclamation-diamond-fill
                                        class="mr-50"
                                        variant="warning"
                                    ></b-icon-exclamation-diamond-fill>
                                    故障程度
                                    <b-badge
                                        variant="warning"
                                        class="float-right"
                                        >{{ detail.degree }}
                                    </b-badge>
                                </p>

                                <p>
                                    <b-icon-exclamation-square-fill
                                        class="mr-50"
                                        variant="info"
                                    ></b-icon-exclamation-square-fill>
                                    报警时间
                                    <span class="float-right"
                                        >{{ detail.create_date }}
                                        <!--                    <b-badge variant="info" class="ml-50">持续 2h 12 min-->
                                        <!--                    </b-badge>-->
                                    </span>
                                </p>
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col cols="6">
                        <b-card class="row1">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-exclamation-triangle-fill
                                    class="mr-50"
                                    variant="primary"
                                ></b-icon-exclamation-triangle-fill>
                                当前信息
                            </b-card-title>
                            <b-row>
                                <b-col cols="4">
                                    <b-alert
                                        show
                                        variant="secondary"
                                        class="py-50"
                                    >
                                        <div class="alert-body">
                                            <h5 class="font-weight-bolder mb-1">
                                                当前温度
                                            </h5>
                                            <p>
                                                <span
                                                    class="h1 text-danger font-weight-bolder"
                                                    >44.2</span
                                                ><small class="ml-50">℃</small>
                                            </p>
                                            正常范围
                                            <b-badge
                                                variant="success"
                                                class="ml-50"
                                                >15-35℃
                                            </b-badge>
                                        </div>
                                    </b-alert>
                                </b-col>

                                <b-col cols="4">
                                    <b-alert
                                        show
                                        variant="secondary"
                                        class="py-50"
                                    >
                                        <div class="alert-body">
                                            <h5 class="font-weight-bolder mb-1">
                                                超出安全范围时间
                                            </h5>
                                            <p>
                                                <span
                                                    class="h1 text-warning font-weight-bolder"
                                                    >9:15</span
                                                >
                                            </p>
                                            已持续
                                            <b-badge
                                                variant="warning"
                                                class="ml-50"
                                                >1小时5分钟
                                            </b-badge>
                                        </div>
                                    </b-alert>
                                </b-col>

                                <b-col cols="4">
                                    <b-alert
                                        show
                                        variant="secondary"
                                        class="py-50"
                                    >
                                        <div class="alert-body">
                                            <h5 class="font-weight-bolder mb-1">
                                                失效预警时长
                                            </h5>
                                            <p>
                                                <span
                                                    class="h1 text-info font-weight-bolder"
                                                    >4</span
                                                ><small class="ml-50">h</small>
                                            </p>
                                            剩余
                                            <b-badge
                                                variant="info"
                                                class="ml-50"
                                                >5 h
                                            </b-badge>
                                        </div>
                                    </b-alert>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>

                    <b-col cols="3">
                        <b-card class="row1">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-exclamation-octagon-fill
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-exclamation-octagon-fill>
                                基本信息
                            </b-card-title>
                            <b-card-text>
                                <p>
                                    <b-icon-clock-fill
                                        class="mr-50"
                                        variant="danger"
                                    ></b-icon-clock-fill>
                                    报警时间
                                    <span class="float-right">{{
                                        detail.create_date
                                    }}</span>
                                </p>

                                <p>
                                    <b-icon-exclamation-triangle-fill
                                        class="mr-50"
                                        variant="success"
                                    ></b-icon-exclamation-triangle-fill>
                                    报警类型
                                    <b-badge
                                        variant="success"
                                        class="float-right"
                                        >{{ detail.type }}</b-badge
                                    >
                                </p>

                                <p>
                                    <b-icon-exclamation-diamond-fill
                                        class="mr-50"
                                        variant="warning"
                                    ></b-icon-exclamation-diamond-fill>
                                    报警等级
                                    <b-badge
                                        variant="warning"
                                        class="float-right"
                                    >
                                        {{ detail.degree }}
                                    </b-badge>
                                </p>

                                <p>
                                    <b-icon-exclamation-square-fill
                                        class="mr-50"
                                        variant="info"
                                    ></b-icon-exclamation-square-fill>
                                    函数名称

                                    <b-badge variant="info" class="float-right">
                                        {{ detail.function_name }}
                                    </b-badge>
                                </p>
                            </b-card-text>
                        </b-card>
                    </b-col>

                    <!-- <b-col cols="3">
                        <b-card class="row1">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-exclamation-square-fill
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-exclamation-square-fill>
                                问题描述
                            </b-card-title>
                            <div
                                id="project-detail-operation-scroll-notice"
                                class="mt-2 font-small-2"
                                style="
                                    height: calc(100% - 3rem);
                                    overflow-y: scroll;
                                    overflow-x: hidden;
                                "
                                @mouseenter="
                                    scroll_stop(
                                        '#project-detail-operation-scroll-notice'
                                    )
                                "
                                @mouseleave="
                                    scroll_active(
                                        '#project-detail-operation-scroll-notice'
                                    )
                                "
                            >
                                <app-timeline>
                                    <app-timeline-item
                                        v-for="(
                                            item, index
                                        ) in projectOperation"
                                        :key="'agent_' + index"
                                        :time="item.time"
                                        :title="item.Description"
                                        :variant="
                                            item.Degree === '重要'
                                                ? 'danger'
                                                : item.Degree === '一般'
                                                ? 'info'
                                                : 'warning'
                                        "
                                        class="pb-1"
                                    >
                                    </app-timeline-item>
                                </app-timeline>
                            </div>
                        </b-card>
                    </b-col> -->
                </b-row>

                <b-row>
                    <b-col cols="6">
                        <b-card class="row2">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-diagram3-fill
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-diagram3-fill>
                                自动诊断图
                                <b-button
                                    variant="primary"
                                    size="sm"
                                    class="float-right"
                                    @click="ToAgentList($route.params)"
                                >
                                    <b-icon-arrows-fullscreen
                                        class="mr-50"
                                    ></b-icon-arrows-fullscreen>
                                    查看
                                </b-button>
                            </b-card-title>

                            <dag-demo
                                style="height: calc(100% - 2rem)"
                            ></dag-demo>
                        </b-card>
                    </b-col>

                    <b-col cols="6">
                        <b-card class="row2">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-bar-chart-line-fill
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-bar-chart-line-fill>
                                故障数据
                                <b-badge style="font-size: 1rem"
                                    >2#空调机组</b-badge
                                >
                                <!--                <div style="width: 15rem" class="float-right">-->
                                <!--                  <b-select></b-select>-->
                                <!--                </div>-->
                            </b-card-title>
                            <div
                                id="canvas"
                                style="height: calc(100% - 2rem)"
                            ></div>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="3">
                        <b-card class="row3">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-zoom-in
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-zoom-in>
                                自动诊断（fault_reason）
                            </b-card-title>

                            <b-card-text>
                                {{ detail.fault_reason }}
                                <!--                <p>-->
                                <!--                  太阳能集热器集热器总回路温度数据连续96次不变化！该报警的原因是运行参数多个周期内数值不变，主要原因为：-->
                                <!--                </p>-->

                                <!--                <p>(1) 监测软件数据传输错误，或者数据短线，导致出现“坏数”。</p>-->
                                <!--                <p>(2) KPI计算软件或网关故障，停止计算。</p>-->
                                <!--                <p class="card_text">(3) 现场设备或传感器故障。</p>-->
                            </b-card-text>
                        </b-card>
                    </b-col>

                    <b-col cols="3">
                        <b-card class="row3">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-wrench
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-wrench>
                                推荐措施(fault_solution)
                            </b-card-title>
                            <b-card-text>
                                {{ detail.fault_solution }}
                                <!--                <p>-->
                                <!--                  太阳能集热器集热器总回路温度数据连续96次不变化！该报警的原因是运行参数多个周期内数值不变，主要原因为：-->
                                <!--                </p>-->

                                <!--                <p>(1) 监测软件数据传输错误，或者数据短线，导致出现“坏数”。</p>-->
                                <!--                <p>(2) KPI计算软件或网关故障，停止计算。</p>-->
                                <!--                <p class="card_text">(3) 现场设备或传感器故障。</p>-->
                            </b-card-text>
                        </b-card>
                    </b-col>
                    <b-col cols="6">
                        <b-card class="row3">
                            <b-card-title class="font-weight-bolder">
                                <b-icon-list-check
                                    variant="primary"
                                    class="mr-50"
                                ></b-icon-list-check>
                                历史情况处理
                            </b-card-title>
                            <div
                                id="canvas1"
                                style="height: calc(100% - 3rem)"
                            ></div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import VJstree from "vue-jstree";
import { Graph } from "@antv/x6";
import {
    onMounted,
    ref,
    reactive,
    toRefs,
    nextTick,
} from "@vue/composition-api";
import { Line, Area } from "@antv/g2plot";
import FlowChart from "./FlowChart.vue";
import DagDemo from "./components/DagDemo.vue";
import { t } from "@core/libs/i18n/utils";
import flatPickr from "vue-flatpickr-component";
import { momentJs } from "@/libs/utils/moment";
import zh from "flatpickr/dist/l10n/zh.js";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { scroll_active, scroll_stop } from "@/libs/utils/autoScroll";
import router from "@/router";
import FunctionEntry from "@/views/front/cabr/diagnosis/dashboard/components/FunctionEntry.vue";
import { watch } from "@vue/composition-api";
import {
    detail_get_degree,
    detail_get_results,
    detail_get_type,
    fault_detail,
} from "@/api/diagnosis";

// import auto_import_panel_components from "@/views/panel-group/components";

export default {
    name: "AgentDetail",
    data: () => ({
        detail: {},
    }),
    directives: {
        Ripple,
    },
    components: {
        FunctionEntry,
        PageTitle,
        vSelect,
        flatPickr,
        downloadExcel: JsonExcel,
        VJstree,
        FlowChart,
        DagDemo,
        AppTimeline,
        AppTimelineItem,
    },
    props: ["hide-title"],
    setup() {
        // 使用 ref 创建响应式数据
        const myDataArray = ref([]);

        const event = reactive({
            filterValues: {
                Type: null,
                // time: momentJs().format(),
                time: null,
                Degree: null,
            },

            filterOptions: {
                Degree: [],
                Type: [
                    // 提供默认选项
                    // {value: "option1", label: "Option 1"},
                    // {value: "option2", label: "Option 2"},
                    // 添加更多默认选项...
                ],
            },
            projectOperation: [
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "警告",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "警告",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "警告",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "警告",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "警告",
                },
            ],
        });

        watch(
            () => event.filterValues.time,
            (v) => {
                event.filterValues.Type = null;
                event.filterValues.Degree = null;
                detail_get_type({ time: v }).then((res) => {
                    event.filterOptions.Type = res.data.map((item) => ({
                        value: item,
                        label: item,
                    }));
                });
            }
        );
        watch(
            () => event.filterValues.Type,
            (v) => {
                event.filterValues.Degree = null;
                detail_get_degree({
                    type: v.value,
                    time: event.filterValues.time,
                }).then((res) => {
                    event.filterOptions.Degree = res.data.map((item) => ({
                        value: item,
                        label: item,
                    }));
                });
            }
        );
        watch(
            () => event.filterValues.Degree,
            (v) => {
                myDataArray.value = [];
                detail_get_results({
                    degree: v.value,
                    time: event.filterValues.time,
                    type: event.filterValues.Type.value,
                }).then((res) => {
                    for (let i in res.data) {
                        const t = {
                            text: i,
                            opened: false,
                            children: res.data[i].map((item) => ({
                                text: item.function_name,
                                id: item.id,
                                data: item,
                            })),
                        };
                        myDataArray.value.push(t);
                    }
                    // event.filterOptions.Degree = res.data.map(item => ({value: item, label: item}))
                });
            }
        );

        // 使用 ref 创建 graph
        // const graph = ref(null);

        // 定义方法
        const itemClick = (node) => {
            console.log(node.model.text + " clicked !");
        };

        let linePlot;
        const initLinePlot = (chartData) => {
            if (!chartData) return;
            if (linePlot) {
                linePlot.data = chartData;
                linePlot.update();
                return;
            }
            linePlot = new Line("canvas", {
                description: {
                    visible: true,
                    text: "将数据按照某一字段进行分组，用于比对不同类型数据的趋势。",
                },
                // padding: "auto",
                // forceFit: true,
                data: chartData,
                xField: "time",
                yField: "value",
                seriesField: "pvvarname",
                yAxis: {
                    label: {
                        // 数值格式化为千分位
                        formatter: (v) =>
                            `${v}`.replace(
                                /\d{1,3}(?=(\d{3})+$)/g,
                                (s) => `${s},`
                            ),
                    },
                },
                legend: {
                    position: "bottom",
                },
                // seriesField: "type",
                // responsive: true,
                animate: true,
                animation: {
                    appear: {
                        animation: "path-in",
                        duration: 10000,
                    },
                    // enter:{
                    //   animation:"path-in",
                    //   duration:30000,
                    // }
                },
            });

            linePlot.render();
        };

        const ToAgentList = (params) => {
            router.push({
                name: "AgentDetail2",
                params,
            });
        };

        // 返回需要在模板中使用的数据和方法
        return {
            ...toRefs(event),
            myDataArray,
            itemClick,
            zh,
            scroll_active,
            scroll_stop,
            ToAgentList,
            initLinePlot,
        };
    },
    methods: {
        hanleClickTreeItem(v) {
            const detail = v.data.data;
            const data = JSON.parse(detail.data);
            const chartData = [];
            for (let i in data.time) {
                const d = new Date(data.time[i]);
                chartData.push({
                    time: `${d.getFullYear()}-${
                        d.getMonth() + 1 < 10
                            ? "0" + (d.getMonth() + 1)
                            : d.getMonth() + 1
                    }-${
                        d.getDate() + 1 < 10
                            ? "0" + (d.getDate() + 1)
                            : d.getDate() + 1
                    } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
                    pvvarname: data.pvvarname[i],
                    value: data.value[i],
                });
            }
            detail.chartData = chartData;
            for (let i in this.detail) {
                this.detail[i] = detail[i];
            }
            this.initLinePlot(chartData);
        },
    },
    mounted() {
        this.detail = this.$route.params.detail;
        if (!this.detail) {
            this.detail = {};
            fault_detail(this.$route.params.id).then((res) => {
                this.detail = res.data;
                const data = JSON.parse(this.detail.data);
                const chartData = [];
                for (let i in data.time) {
                    const d = new Date(data.time[i]);
                    chartData.push({
                        time: `${d.getFullYear()}-${
                            d.getMonth() + 1 < 10
                                ? "0" + (d.getMonth() + 1)
                                : d.getMonth() + 1
                        }-${
                            d.getDate() + 1 < 10
                                ? "0" + (d.getDate() + 1)
                                : d.getDate() + 1
                        } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
                        pvvarname: data.pvvarname[i],
                        value: data.value[i],
                    });
                }
                this.detail.chartData = chartData;
                console.log(chartData, "chartData");
                this.initLinePlot(this?.detail?.chartData);
            });
        } else {
            this.initLinePlot(this?.detail?.chartData);
        }
        // console.log('aaa')
        // console.log(this.$route.params)
        const data4 = [
            {
                country: "北美",
                date: 1965,
                value: 1390.5,
            },
            {
                country: "北美",
                date: 1966,
                value: 1469.5,
            },
            {
                country: "北美",
                date: 1967,
                value: 1521.7,
            },
            {
                country: "北美",
                date: 1968,
                value: 1615.9,
            },
            {
                country: "北美",
                date: 1969,
                value: 1703.7,
            },
            {
                country: "北美",
                date: 1970,
                value: 1767.8,
            },
            {
                country: "北美",
                date: 1971,
                value: 1806.2,
            },
            {
                country: "北美",
                date: 1972,
                value: 1903.5,
            },
            {
                country: "中南美",
                date: 1965,
                value: 109.2,
            },
            {
                country: "中南美",
                date: 1966,
                value: 115.7,
            },
            {
                country: "中南美",
                date: 1967,
                value: 120.5,
            },
            {
                country: "中南美",
                date: 1968,
                value: 128,
            },
            {
                country: "中南美",
                date: 1969,
                value: 134.4,
            },
            {
                country: "中南美",
                date: 1970,
                value: 142.2,
            },
            {
                country: "中南美",
                date: 1971,
                value: 157.5,
            },
            {
                country: "中南美",
                date: 1972,
                value: 169.5,
            },
            {
                country: "中南美",
                date: 1973,
                value: 186.3,
            },
            {
                country: "中南美",
                date: 1974,
                value: 195.5,
            },
            {
                country: "中南美",
                date: 1975,
                value: 198,
            },
        ];
        // this.scroll_active("#project-detail-operation-scroll-notice");

        const areaPlot = new Area("canvas1", {
            title: {
                visible: true,
                text: "堆叠面积图",
            },
            data: data4,
            xField: "date",
            yField: "value",
            seriesField: "country",
            animate: true,
            animation: {
                appear: {
                    animation: "wave-in",
                    duration: 10000,
                },
                // enter:{
                //   animation:"path-in",
                //   duration:30000,
                // }
            },
        });

        areaPlot.render();
    },
};
</script>

<style scoped lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
@import "../../../../@core/scss/vue/libs/vue-select";

.page {
    height: 100vh;
}

/* 在你的样式文件中添加以下样式 */
// .jstree-clicked {
//   background-color: red !important;
// }/* 添加自定义样式，根据实际需求调整 */
// .tree-default .tree-wholerow-clicked {
//   background-color: red !important;
// }

.tree-default ::v-deep .tree-selected {
    // background: #b40a0a !important;
    background: unset !important;
    border: 0;
    box-shadow: none;
}

.tree-default ::v-deep .tree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 30px;
    min-width: 300px !important;
}

.row1 {
    height: 15rem;
}

.row2 {
    //   height: 24rem;
    height: 30rem;
}

.row3 {
    height: 18rem;
}

.row5 {
    height: 30rem;
}

.text1 {
    // font-weight: bold;
    // font-size: 14px;
    // color: black;
}

.text2 {
    margin-bottom: 3px;
    font-size: 14px;
}

.text4 {
    font-size: 10px;
}

.card_text {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.red-text-shadow {
    text-shadow: 0 0 0 red, 0 0 0 red, 0 0 0 red, 0 0 0 red;
}

.info-text-shadow {
    text-shadow: 0 0 0 rgb(98, 204, 230), 0 0 0 rgb(98, 204, 230),
        0 0 0 rgb(98, 204, 230), 0 0 0 rgb(98, 204, 230);
}

.success-text-shadow {
    text-shadow: 0 0 0 rgb(100, 195, 119), 0 0 0 rgb(100, 195, 119),
        0 0 0 rgb(100, 195, 119), 0 0 0 rgb(100, 195, 119);
}

// .v-select {
//   background-color: transparent !important;
//   border: 1px solid #ced4da; /* 设置你想要的边框颜色 */
// }

.timeline-item {
    padding-left: 1.5rem !important;

    div > h6 {
        font-size: 0.9rem !important;
    }
}
</style>
