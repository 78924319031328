import {Graph, Node, Edge, Shape} from '@antv/x6'

// 定义节点
class TreeNode extends Node {
    collapsed = false


    constructor(metadata, collapsed) {
        // super(metadata);
        super({...metadata, height: 60, width: 180});
        // console.log(metadata, 'metadata')
        this.collapsed = collapsed;
    }

    postprocess = () => {
        this.toggleCollapse(false)
    }

    isCollapsed() {
        return this.collapsed
    }

    toggleButtonVisibility(visible) {
        this.attr('buttonGroup', {
            display: visible ? 'block' : 'none',
        })
    }

    toggleCollapse(collapsed) {
        const target = collapsed == null ? !this.collapsed : collapsed
        if (!target) {
            this.attr('buttonSign', {
                d: 'M 1 5 9 5 M 5 1 5 9',
                strokeWidth: 1.6,
            })
        } else {
            this.attr('buttonSign', {
                d: 'M 2 5 8 5',
                strokeWidth: 1.8,
            })
        }
        this.collapsed = target
    }
}

TreeNode.config({
    zIndex: 2,
    markup: [
        {
            tagName: 'g',
            selector: 'buttonGroup',
            children: [
                {
                    tagName: 'rect',
                    selector: 'button',
                    attrs: {
                        'pointer-events': 'visiblePainted',
                    },
                },
                {
                    tagName: 'path',
                    selector: 'buttonSign',
                    attrs: {
                        fill: 'none',
                        'pointer-events': 'none',
                    },
                },
            ],
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'processBg',
        },
        {
            tagName: 'rect',
            selector: 'process',
        },
        {
            tagName: 'text',
            selector: 'label',
        },
        {
            tagName: 'text',
            selector: 'system',
        },
        {
            tagName: 'image',
            selector: 'state',
        },
        {
            tagName: 'text',
            selector: 'stateText',
        },
    ],
    attrs: {
        body: {
            refWidth: '100%',
            refHeight: '100%',
            strokeWidth: 0,
            fill: '#E3F2FD',
            stroke: '#5F95FF',
            ry: 4,
        },
        label: {
            textWrap: {
                ellipsis: true,
                width: -10,
            },
            // textAnchor: 'middle',
            // textVerticalAnchor: 'middle',
            // refX: '50%',
            // refY: '50%',
            fill: '#1890ff',
            refX: '14px',
            refY: '12px',
            //fontWeight: 800,
            fontSize: 14,
        },
        system: {
            textWrap: {
                ellipsis: true,
                width: -10,

            },
            // textAnchor: 'middle',
            // textVerticalAnchor: 'middle',
            // refX: '50%',
            // refY: '50%',
            fill: '#82868b',
            refX: '14px',
            refY: '33px',
            //fontWeight: 800,
            fontSize: 12,
        },
        state: {
            textWrap: {
                ellipsis: true,
                width: -10,
            },
            'xlink:href': 'https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/assets/danger.png',
            // textAnchor: 'middle',
            // textVerticalAnchor: 'middle',
            // refX: '50%',
            // refY: '50%',
            // fill: '#28c76f',
            refX: '150px',
            refY: '19px',
            width: '18px',
            height: '18px',
            // fontWeight: 800,
            // fontSize: 12,
        },
        stateText: {
            textWrap: {
                ellipsis: true,
                width: -10,
                text: ''
            },
            // textAnchor: 'middle',
            // textVerticalAnchor: 'middle',
            // refX: '50%',
            // refY: '50%',
            // fill: '#28c76f',
            refX: '120px',
            refY: '33px',
            width: '18px',
            height: '18px',
            fontSize: 12,
            fill: '#1890ff',
            // fontWeight: 800,
            // fontSize: 12,
        },
        buttonGroup: {
            refX: '100%',
            refY: '50%',
        },
        button: {
            fill: '#1890ff1f',
            stroke: 'none',
            x: -0,
            y: -10,
            height: 20,
            width: 20,
            rx: 10,
            ry: 10,
            cursor: 'pointer',
            event: 'node:collapse',
        },
        process: {
            fill: '#1890ff',
            //stroke: '#1890ff',
            //strokeWidth: 1,
            // x: -10,
            // y: -10,
            height: 5,
            width: 0,
            refX: '0',
            refY: '55px',
            ry: 2,
            cursor: 'pointer',
            event: 'node:collapse',
            zIndex: 2,
        },
        processBg: {
            fill: '#1890ff3c',
            //stroke: '#1890ff',
            //strokeWidth: 1,
            // x: -10,
            // y: -10,
            height: 5,
            width: 180,
            refX: '0',
            refY: '55px',
            ry: 2,
            cursor: 'pointer',
            event: 'node:collapse',
            zIndex: 1,
        },
        buttonSign: {
            refX: 5,
            refY: -5,
            stroke: '#1890ff',
            strokeWidth: 1.5,
            d: 'M 1 5 9 5 M 5 1 5 9',
        },
    },
})


// 定义边
class TreeEdge extends Shape.Edge {
    isHidden() {
        const node = this.getTargetNode()
        return !node || !node.isVisible()
    }
}

TreeEdge.config({
    zIndex: 1,
    attrs: {
        line: {
            stroke: '#82868b',
            strokeDasharray: 5,
            targetMarker: 'classic',
            style: {
                animation: 'ant-line 30s infinite linear',
            },
        },
    },
})

export {
    TreeNode,
    TreeEdge
}
